import React from 'react';
import styled from 'styled-components';
// import ListItemContainer from '../../components/ThankyouPages/ListItemContainer';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const PhoneNum = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1vh 5vw;
  background: #003060;
  p {
    margin: 0;
    font-size: clamp(18px, 2vw, 22px);
    color: #fff;
  }
  a {
    color: #fff;
    :hover {
      color: #76e0fa;
    }
  }
`;
const LogoBlock = styled.div`
  width: 90%;
  max-width: 450px;
  margin: auto;
  .nmls {
    text-align: right;
    color: #002f5f;
    font-weight: 600;
  }
`;
const NewRezContainer = styled.div`
  text-align: center;

  h1 {
    font-size: clamp(22px, 4vw, 36px);
    font-weight: 400;
    margin-top: 20px;
    color: #002f5f;
    width: calc(100% - 30px);
    max-width: 660px;
    margin: 20px auto 1.45rem;
  }

  img {
    width: 100%;
    max-width: 450px;
    margin: 20px auto 10px;
  }
`;

const SubSection = styled.section`
  width: 100%;
  max-width: 660px;
  padding: 0;
  margin: auto;
  border-radius: 0;
  margin-bottom: 0;

  background: ${({ background }) => background && background};
  border-top: ${({ border }) => border && border};
  color: ${({ color }) => color && color};
  .headerBlock {
    background: #003060;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
  }
  h2 {
    text-align: center;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: clamp(22px, 3vw, 32px);
    width: calc(100% - 30px);
  }
`;
const ListContainer = styled.div`
  width: 100%;
  padding: 20px;
`;
const ListItem = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  p {
    max-width: calc(100% - 60px);
    margin: 0;
    text-align: left;
    font-size: clamp(18px, 2.5vw, 22px);
  }
`;

const ItemNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  font-weight: 600;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #dedede;
  color: #003060;
`;
const CheckmarkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  margin: 0 15px 0 0;
  img {
    margin: 0;
  }
`;

const NumListItemContainer = ({ items }) =>
  items.map((item, itemIndex) => (
    <ListItem key={itemIndex}>
      <ItemNumber>{itemIndex + 1}</ItemNumber>
      <p>{item}</p>
    </ListItem>
  ));
const ListItemContainer = ({ items }) =>
  items.map((item, itemIndex) => (
    <ListItem key={itemIndex}>
      <CheckmarkContainer>
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/newRezCheckmark.png"
          alt="checkmark"
        />
      </CheckmarkContainer>
      <p>{item}</p>
    </ListItem>
  ));

const sectionOne = [
  'We will give you a call shortly.',
  'A NewRez loan advisor will walk you through the process and help you find the best loan option for your needs.',
  'We will verify your information and help you lock in a new rate!',
];

const sectionTwo = [
  'Can I lower my monthly mortgage payment?',
  'Should I switch fomr a 30-year to a 15-year?',
  'Can I take cash out of my mortgage?',
  'Can I manage my mortgage online?',
];

const NewRezImgSrc =
  'https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/newrez-logo.png';

const NewRezThankyou = ({
  pageContext: {
    firstListArr = sectionOne,
    secondListArr = sectionTwo,
    imgSrc = NewRezImgSrc,
  },
}) => (
  <NewRezContainer>
    <PhoneNum>
      <p>
        Talk to an Agent: <a href="tel:18882256259">1-888-225-6259</a>
      </p>
    </PhoneNum>
    <h1>You've been connected with our featured provider, NewRez</h1>
    <LogoBlock>
      <img src={imgSrc} alt="New Rez Logo" />
      <p className="nmls">NMLS #3031</p>
    </LogoBlock>

    <SubSection
      background="#76E0FA"
      color="#002E61"
      border="10px solid #76e0fa"
    >
      <div className="headerBlock">
        <h2>What Happens Next?</h2>
      </div>
      <ListContainer>
        <NumListItemContainer items={firstListArr} />
      </ListContainer>
    </SubSection>
    <SubSection background="#76E0FA" color="#002E61">
      <div className="headerBlock">
        <h2>
          Have Questions?
          <br />
          Your NewRez Loan Advisor can help.
        </h2>
      </div>
      <ListContainer>
        <ListItemContainer items={secondListArr} />
      </ListContainer>
    </SubSection>
    {/* <AdButler adButlerIds={adIds} /> */}
  </NewRezContainer>
);

export default withThankyouWrapper(NewRezThankyou);
